.overviewPayroll {
  border-collapse: collapse;
  width: 280mm
    /*table-layout: fixed;*/
}

.overviewPayroll td {
  font-size: 10px !important;
  vertical-align: top;
}

.rowTop {
  border-top: 1px solid black;
}

.rowBottom {
  border-bottom: 1px solid black;
}

.myTH {
  text-align: center;
  border: 1px solid black;
  padding: 0px;
  margin: 0px;
  font-size: 10px !important;
}


.lastRow {
  border: 0px solid black;
  font-size: 10px !important;
  font-weight: 900;
  text-align: right;
  padding-left:3px;
}

.cc1 {
  width: 5mm !important;
  height: 6mm;
  text-align: right;
}

.cc2 {
  width: 37mm !important;
  height: 6mm;
  text-align: left;

}

.cc3,
.cc10 {
  width: 10mm !important;
  height: 6mm;
  text-align: center;
}

.cc4,
.cc5 {
  width: 19mm !important;
  height: 6mm;
  text-align: left;
}

/* COLUMN: START, END */
.cc6,
.cc7 {
  width: 14mm !important;
  height: 6mm;
  text-align: right;
}

/*category*/
.cc8 {
  width: 14mm !important;
  height: 6mm;
  text-align: left;
}

/*Rates*/
.cc9 {
  width: 12mm !important;
  height: 6mm;
  text-align: right;
}

.cc11 {
  width: 13mm !important;
  height: 6mm;
  text-align: right;
}

.cc12,
.cc13,
.cc14,
.cc15,
.cc17,
.cc18,
.cc19,
.cc20,
.cc21,
.cc22 {
  width: 11mm !important;
  height: 6mm;
  text-align: right;
}
/*Net Sal*/
.cc16 {
  width: 14mm !important;
  height: 6mm;
  text-align: right;
}

.bLeft {
  border-left: 1px solid black;
}

.bRight {
  border-right: 1px solid black;
}

@media print {
  @page {
    size: 297mm 210mm;
    margin: 10mm;
  }

}