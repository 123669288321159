.overviewTable {
  border-collapse: collapse;
  width: 405mm;
  /*table-layout: fixed;*/
}

.overviewTable td {
  font-size: 9px !important;
  vertical-align: top;
}

.row {
  border-top: 1px solid black;
}

.rowBottom {
  border-top: 1px solid black;
}

.c1 {
  width: 5mm !important;
  height: 6mm;
  text-align: right;
}

.c2 {
  width: 45mm !important;
  height: 6mm;
  text-align: left;

}

.c3,
.c11 {
  width: 10mm !important;
  height: 6mm;
  text-align: center;
}

.c4,
.c5 {
  width: 15mm !important;
  height: 6mm;
  text-align: left;
}

.c6 {
  width: 8mm !important;
  height: 6mm;
  text-align: right;
}
/* COLUMN: START, END */ 
.c7,
.c8 {
  width: 14mm !important;
  height: 6mm;
  text-align: right;
}

.c10,
.c31 {
  width: 12mm !important;
  height: 6mm;
  text-align: right;
}

.c9 {
  width: 14mm !important;
  height: 6mm;
  text-align: left;
}

.c12,
.c13,
.c22,
.c23 {
  width: 14mm !important;
  height: 6mm;
  text-align: right;
}

.c14,
.c15,
.c16,
.c17,
.c24,
.c25,
.c30 {
  width: 13mm !important;
  height: 6mm;
  text-align: right;
}

.c18,
.c19,
.c20,
.c21,
.c26,
.c27,
.c28,
.c29,
.c32 {
  width: 10mm !important;
  height: 6mm;
  text-align: right;
}

.bLeft {
  border-left: 1px solid black;
}

.dbLeft {
  border-left: double black !important;
}

.bRight {
  border-right: 1px solid black;
}

.tAlign {
  text-align: center;
  border: 1px solid black;
  padding: 0px;
  margin: 0px;
  font-size: 9px !important;
}

@media print {
  @page {
    size: 420mm 297mm;
    margin: 10mm;
  }
  .printTest {
    margin: 0mm;
  }
}