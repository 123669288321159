table {
  border-collapse: collapse;
  width: 920px;
}

th,
td {
  padding: 2px;
  text-align: left;
  font-size: 12px !important;
  /*
  border: 1px solid #ccc;
  font: normal small/1.4 sans-serif;
  */

}


tr:hover {
  background-color: silver;
}


/*
tbody tr:nth-child(odd) {
  background: #eee;
}*/

.thRow th {
  font-weight: bold;
  text-align: center;
}

.thNo {
  width: 2%;
}

.thRank {
  width: 13%;
}

.thRate {
  width: 6%;
}

.thDate {
  width: 9%;
}

.thWages {
  width: 7%;
}

.myTextAlignRight {
  text-align: right;
}

.myTextAlignCenter {
  text-align: center;
}

.firstRow td {
  border-top: 1px solid darkslategrey;
  border-left: 1px solid darkslategrey;
  border-right: 1px solid darkslategrey;
}

.secondRow td {
  border-left: 1px solid darkslategrey;
  border-right: 1px solid darkslategrey;
}

.fifthRow td {
  border-top: 1px solid darkslategrey;
}

.leftBorder td {
  border-left: 1px solid darkslategrey;

}

.totalRow {
  font-weight: bold;
  text-align: right;
  text-decoration: underline;
}

.rowHeight {
  height: 20px;
}

