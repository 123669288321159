.AccountMenu {
  position: absolute;
  right: 10px;
}

.main {
  /*margin-right: auto; */
  position: absolute;
  background-color: aqua;
  width: 20cm;
  height: 28cm;
  overflow: hidden;
  padding-left: 1cm;

}